import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image";
import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper } from '../components'
import website from '../../config/website'

import {
  CatalogItem,
  CatalogHeader,
} from '../components'
import {
  CatalogWrapper
} from '../templates/catalogItemStyle'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 1.35rem;
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: "Lato" !important;
    font-size: 1rem;
    line-height: 1.4rem;
    padding: 10rem 2rem 0 2rem;
    display: block;
  }
  h3.new {
    background:#fce477;
    color: #000;
    display: inline-block;
    border-radius: 5px;
    padding: 5px 10px;
    ${'' /* font-weight: bold; */}
    margin: 10px 0;
    font-size: 26px;
  }
  p.new {
    color: #283726;
    padding: 5px 0;
    margin: 0;
    a {
      font-weight: bold
    }
  }

  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
    .signature, .signature a {
      font-size: 16px;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    span {
      font-family: "Lato" !important;
      font-size: 1.2rem;
      line-height: 1.4rem;
      padding: 1rem 0;
      display: block;
    }
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

const NovembreMMXXIV = ({ data }) => {

  const context = { slug: "exhibition", parent: "" };
  const artworks = data.artworks.nodes;
  
  return (
    <React.Fragment>
      <Helmet title={`Disch Rémy - La lettre`}>
        <link rel="stylesheet" type="text/css" href="https://unpkg.com/xzoom/dist/xzoom.css" media="all" />
        <script defer data-domain="dischr.com" src="https://plausible.io/js/script.js"></script>
      </Helmet>
      <CatalogHeader {...context} letter />
      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0}}>
        <Inner>
          <Head>
            <Img
              fixed={data.banner.childImageSharp.fixed}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            />
            <Hero style={{ position: 'absolute', bottom: 0, left: '1rem' }}>
              <div style={{ width: '100%' }}>
                <h3 style={{ color: '#fff', opacity: .8 }}>
                  <small>Novembre 2024</small><br />
                </h3>
              </div>
            </Hero>
          </Head>
          <Hero>
            <div style={{ width: '100%', paddingTop: '2rem', paddingBottom: '2rem', paddingLeft: 0, paddingRight: 0 }}>
              <p>Bonjour,</p>
              
              <p><strong>Les nuages effleurent ma tête.</strong></p>
              
              <p>
                Après plusieurs semaines de travail, guidées par vos précieux retours, je suis heureux de vous annoncer l'ouverture de ma boutique en ligne. Pour la première fois, vous pourrez découvrir et acquérir les œuvres sur papier de la série <strong>"Portraits"</strong>, rassemblées et accessibles à tous. 
              </p>

              <p>L'achat d'art se dématérialise, mais l'œuvre reste profondément réelle.</p>

              <p>
                Pour cette aventure en ligne, j'ai choisi une plateforme simple et fiable : <a style={{ fontSize: "1.1rem", textDecoration: "underline", fontWeight: "bold" }} href="https://shop.dischr.com/" target="_blank">Shopify</a>, un espace clair et serein, parfaitement adapté à ce chemin. 
                <br />(<i>Vous bénéficiez d'une réduction de 35% sur toutes les oeuvres pendant une durée de 48h avec le code "LE CERCLE"</i>)
              </p>

              <p>
                Une autre grande nouvelle est la création d'une page sur la plateforme participative <a style={{ fontSize:"1.1rem",textDecoration: "underline", fontWeight: "bold" }} href="https://www.patreon.com/remy_disch" target="_blank">Patreon</a>. Bien plus qu'un simple soutien financier, elle incarne un échange, une relation symbiotique où chaque contribution devient le souffle qui anime l'œuvre. Votre aide me permet de consacrer pleinement mon temps à l'art, de nourrir la création sans dispersion, et de respecter l'équilibre essentiel entre donner et recevoir.
              </p>

              <p>
                Pour ceux qui ne suivent pas encore mes créations sur Instagram, c'est l'occasion de découvrir mes dernières huiles. Quant aux toiles, le catalogue reste votre point de rencontre privilégié.
              </p>
              
              <p>
                Les doutes qui m’effleurent se dissipent à la lueur des signes extérieurs : ma première couverture parue cet été dans la revue d'art <strong>Ogzylone de Valérie Mégardon</strong> (une deuxième publication dans ce magazine), ou encore les messages émouvants d’un public que je ne connais pas, mais dont les mots me rappellent pourquoi je peins.
              </p>

              <p>
                Je crois fermement que chaque être humain porte en lui un élan inné à persévérer. Comprendre que tout ce qui nous touche vient souvent de causes extérieures, sur lesquelles nous n'avons pas toujours de prise, est une leçon précieuse.
              </p>

              <p>
                Je peins, car cela me nourrit et apporte du bien à ceux qui croisent mon travail.
              </p>

              <p>
                Les nuages effleurent ma tête, et pourtant, je vois le soleil.
              </p>

              <p>Je reste à votre disposition si vous désirez des renseignements complémentaires, Je serais ravi de vous lire.</p>
              
              <p>
                Prenez soin de vous.<br />
                Rémy
              </p>
              
            </div>
          </Hero>
        </Inner>
        <hr />
      </IndexWrapper>
      
      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>

              <div>
                <h3 className="new">Nouveau</h3>
                <p className="new">
                  - Découvrez <a className="regular-link" href="https://shop.dischr.com/" target="_blank">ma nouvelle boutique en ligne "Portraits"</a>,
                  rassemblant une sélection d'huiles sur papier à un prix très abordable. Bénéficiez pour l'ouverture de cette boutique d'une réduction exceptionnelle de 35% pendant 48h sur toutes les oeuvres avec le code "<strong>LE CERCLE</strong>".
                </p>
                <p className="new">
                  - Rejoignez <a className="regular-link" href="https://www.patreon.com/remy_disch" target="_blank">ma page Patreon</a> et accédez aux coulisses de mon cheminement de création tout en soutenant mon travail à partir de 5€ par mois.
                </p>
              </div>
              
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId}>
        <hr />
        <Inner>
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              <Hero>
                <a className="regular-link" href={data.og1.publicURL} target="_blank">
                  <img src={data.og1.childImageSharp.fixed.srcWebp} alt={`Revue Ogzylone - Valérie Mégardon`} />
                </a>
                <span>
                  <strong>Ogzylone</strong>, revue d'art contemporain<br />Valérie Mégardon - juillet 2024
                </span>
              </Hero>
          </div>
        </Inner>
        <hr />
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
              <h3>Acquérir des oeuvres, soutenir mon travail</h3>

              <p>
                Si vous souhaitez acquérir et/ou vous informer de la disponibilité des oeuvres,
                je vous invite à me contacter directement en utilisant l'adresse email en pied de page.
              </p>
              
              <p>
                Vous pouvez également acquérir des oeuvres sur le site de mon partenaire <a className="regular-link" href="https://www.artsper.com/es/artistes-contemporains/france/102408/remy-disch" target="_blank">Artsper</a>
              </p>

              <h3 style={{ marginTop: '3rem' }}>Les catalogues</h3>
              <p>
                Vous pouvez accéder au <strong>catalogue des oeuvres 2024</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog/2024`} target="_blank">https://www.dischr.com/catalog/2024</a>{` `} <br /><br />
                et au <strong>catalogue fonds d'atelier</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog/fonds-d-atelier`} target="_blank">https://www.dischr.com/catalog/fonds-d-atelier</a>{` `}
              </p>

              <h3 style={{ marginTop: "3rem" }}>Prints</h3>
              <p style={{ marginBottom: '5rem' }}>
                Vous pouvez vous procurer des prints d'une large sélection de mes oeuvres sur <a className="regular-link" href="https://www.icanvas.com/canvas-art-prints/artist/remy-disch" target="_blank">iCanvas</a>
              </p>
              
              <p>
                ---
                <br /><h3 style={{ margin: 0, paddingBottom: "10px", paddingTop: "10px" }}>Rémy Disch</h3>
                <div className="signature">
                  Email : <a className="regular-link" href="mailto: davidbraud@dischr.com">dischr@gmail.com</a>
                  <br />Site internet : <a className="regular-link" href={`https://www.dischr.com`} target="_blank">www.dischr.com</a>
                  <br />Boutique en ligne : <a className="regular-link" href={`https://shop.dischr.com`} target="_blank">shop.dischr.com</a>
                  <br />Patreon : <a className="regular-link" href={`https://www.patreon.com/remy_disch`} target="_blank">patreon.com/remy_disch</a>
                </div>
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  );
};

export default NovembreMMXXIV;

export const pageQuery = graphql`
  query NovembreMMXXIV {
    banner: file(name: {eq: "Bandeau_lettre7"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sub_banner: file(name: {eq: "Bandeau_revue"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    og1: file(name: {eq: "ogzylone-10-11-2024-full"}) {
      publicURL
      childImageSharp {
        fixed(width: 600, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    og2: file(name: {eq: "revue-02-c"}) {
      publicURL
      childImageSharp {
        fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    artworks: allPrismicArtwork(
        filter: {
            prismicId: {
                in: [
                    "ZVeLNhIAACwAK99S", 
                    "ZUeTtRUAACwA13_k", 
                ]
            }
        }, 
        sort: {
            order: DESC, 
            fields: first_publication_date
        }) {
      nodes {
        prismicId
        data {
          meta_description
          meta_title
          title {
            text
          }
          drouot_estimate
          catalog_fullscreen
          type
          available
          on_demand
          price
          item {
            alt
            localFile {
              childImageSharp {
                fixed(height: 800, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          cadre {
            alt
            localFile {
              childImageSharp {
                fixed(height: 800, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_hd {
            localFile {
              childImageSharp {
                original {
                  height
                  src
                  width
                }
              }
            }
          }
          categories {
            ... on PrismicArtworkDataCategories {
              category {
                uid,
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
          year {
            ... on PrismicArtworkDataYear {
              uid
            }
          }
        }
      }
    }
  }
`
